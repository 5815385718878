body {
  background-color:#212529;
}

body::-webkit-scrollbar {
  display: none;
}

.carousel-item:after {
  content:"";
  display:block;
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background:rgba(0,0,0,0.4);
}

.carousel-caption {
  z-index: 2;
}

#about {
  background-color: #e3e1de;
}

.logo {
  font-size: 1.3rem;
}

.footerLogo {
  width: 15%;
  height: auto;
}

.footer-font {
  font-size: 0.8rem;
}

.profile {
  height: 30px;
}

.nav-link{
  cursor: pointer;
  color: #212529;
}

.nav-link:hover{
  color: #212529;
}

.nav.nav-pills .nav-link.active {
  background-color: #212529;
}

iframe[src*="docs.google.com"]{
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 10;
}

html {
  scroll-behavior: smooth;
}

*::-webkit-scrollbar{
  width: 5px;
}

*::-webkit-scrollbar-thumb{
  background: rgba(200, 200, 200, 0.8);
  border-radius: 10px;
}

.topics{
  max-height: 25vh;
  overflow-y: scroll;
}

.resource-link{
  color: #212529;
}

a.listItem{
  color: #212529;
  text-decoration: none;
}

a.listItem:hover{
  color:#0d6efd;
}

.youtube {
  width: 100%;
  aspect-ratio: 16/10 !important;
}